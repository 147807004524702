import 'babel-polyfill';
import './configure';
import './main.css';
import { loadPolyfills } from './polyfills';

import { getLocalFromLocation } from './url';

loadPolyfills()
  .then(() => {
    return import(
      /* webpackChunkName: "app-main" */
      './bootstrapper'
    );
  })
  .then(async ({ bootstrapApplication }) => {
    // eslint-disable-next-line
    return bootstrapApplication().catch((err) => {
      handleUnexpectedException(err);
    });
  })
  .then(() => {
    // this polyfill is for lazy loading images in older browsers, and is needed
    // even in newer browsers to allow images in noscript tags to show. This polyfill
    // must be loaded after rehydration completes rather than before.
    require('loading-attribute-polyfill');
  });

const handleUnexpectedException = (err: any) => {
  const errorCode: string = err.code ? err.code : 'general';
  const errorPageUrl = buildErrorPageUrl(errorCode);

  window.location.assign(errorPageUrl);
};

const buildErrorPageUrl = (errorCode: string): string => {
  const local = getLocalFromLocation(window.location);
  const errorPageURL = `${window.location.protocol}//${window.location.host}/${local}/errors/${errorCode}.html`;

  return errorPageURL;
};
