/* eslint-disable */
// @ts-nocheck
import 'core-js';
import 'core-js/es/promise';

// Eventually, we should include all our vendor code here.
// For now, it essentially functions as a polyfill file.

// loading-attribute-polyfill is located in bootstrapper.ts instead
// since it needs to run after rehydration.

if (typeof window !== 'undefined') {
  require('whatwg-fetch');
  require('element-closest');
  require('intersection-observer');
  // To add to window
  // eslint-disable-next-line
  if (!(window as any).Promise) {
    // eslint-disable-next-line
    (window as any).Promise = require('promise-polyfill');
  }
}

export const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

export const loadPolyfills = async () => {
  if (isIE11) {
    const { load } = await import(
      /* webpackChunkName: "polyfills/ie11-shim" */
      './ie11'
    );
    await load();
  }

  return Promise.resolve();
};

if (Element.prototype.getAttributeNames == undefined) {
  Element.prototype.getAttributeNames = function () {
    const attributes = this.attributes;
    const length = attributes.length;
    const result = new Array(length);
    for (const i = 0; i < length; i++) {
      result[i] = attributes[i].name;
    }
    return result;
  };
}
