// Configuration is used to set site-wide properties, such as class name
// prefixes.
//
// In order to work correctly, the configuration should always be the first
// piece of code to be imported and run on a page. This ensures that any code
// which is imported after the configuration has access to the correct values.
//
// To accomplish this, make sure that `import "./configure.js";` is the first
// line in the entry point to the application.
// import { updateConfiguration } from "@dcl/ui/dist/utilities/configuration";

global.emcmUiConfig = {
  styles: {
    namespace: 'tr',
  },
};
